import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from 'react'

export type LayoutConfig = {
  header?: 'sticky' | 'static' | 'none'
}

export type SetLayoutConfig = Dispatch<SetStateAction<LayoutConfig>>

export const defaultLayoutConfig: LayoutConfig = { header: 'static' }

export const LayoutContext = createContext<[LayoutConfig, SetLayoutConfig] | null>(null)

export const useLayoutConfig = ({ header }: LayoutConfig) => {
  const [_prevConfig, setConfig] = useContext(LayoutContext) ?? []
  const [{ header: prevHeader }] = useState<LayoutConfig>(_prevConfig ?? defaultLayoutConfig)
  useEffect(() => {
    if (header === prevHeader) return
    setConfig?.({ header })
    return () => {
      setConfig?.({ header: prevHeader })
    }
  }, [header, prevHeader, setConfig])
}
