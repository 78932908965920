import { Client, DeleteConfig, GetConfig, PostConfig, convertToServerData } from 'client'
import { every, not, some } from 'utils/compose'
import { createDiff } from 'utils/diff'
import { getFullNameOrEmail } from 'utils/full-name'
import { ListQuery, Order, createPaginatedList, parseOrder, parsePagination } from 'utils/list'
import { PickType } from 'utils/type-utils'
import { User, UserFinance, UserRole } from './user'
import { AccountScore } from '../src/account-score.admin'
import { ScoreWarnings, UserScoreWarning } from '../src/score-warnings'

export interface AdminUser extends User {
  ai_summary?: string
  /** @deprecated */
  assets?: number
  /** Amount of assets based on uploaded docs */
  assets_docs?: number
  connected_accounts?: number
  estimated_gross_income_max?: number
  estimated_gross_income_min?: number
  guarantor?: AdminUser
  /**
   * Guarantor reported Gross Income + Weighted avg of balances dividen by 40.\
   * Will be calculated if `reported_gross_income` and `liquid_current` are not empty.
   */
  guarantor_legacy_score?: number
  /**
   * When we are computing the score of a guarantor we should disregard rent payments
   * (we need actual free cash flow, and they should not receive credit for rent payment)
   * if we add this user as a guarantor to an application/offer we should use the guarantor score
   */
  guarantor_score?: number
  identity_verified_at?: string
  income_data?: number[]
  /** Annual income based on uploaded docs */
  income_docs?: number
  income_months?: number
  invitation_accepted_at?: string
  invite_unit_id?: string
  invited_by_id?: string
  last_activity_at?: string
  /**
   * Cosigner reported Gross Income + Weighted avg of balances dividen by 40.\
   * Will be calculated if `reported_gross_income` and `liquid_current` are not empty.
   */
  legacy_score?: number
  liquid_average?: number
  liquid_current?: number
  net_income?: number
  past_rent?: number
  public_records?: number
  rent_data?: number[]
  rent_months?: number
  score_warnings?: ScoreWarnings
  /** for each warning if true it should be hidden on the user */
  score_warnings_config?: Partial<Record<ScoreWarnings.Type, boolean>>
  stripe_customer_id?: string
  monthly_data?: AccountScore.MonthData[]
}

export namespace AdminUser {
  export type IdField = 'user_id'
  export type Id = Pick<AdminUser, IdField>
  export type Brief = User.Brief
  export type WithFinance = AdminUser & { finance?: UserFinance }
  export type WithAccountScore = AdminUser & { scores: AccountScore[] }

  export const MSG = {
    CREDIT_REPORT: 'Credit Score Report',
    ACTIONS: {
      CREATE: 'Create User',
      CREATE_SUBMIT: 'Create',
      CREATE_SUCCESS: 'User created.',
      EDIT: 'Edit User',
      EDIT_SUBMIT: 'Update',
      EDIT_SUCCESS: 'User updated.',
      PHOTO_ID_VIEW: 'View Photo ID',
      GUARANTOR_SET: 'Set Guarantor',
      GUARANTOR_SET_SUBMIT: 'Set',
      GUARANTOR_SET_SUCCESS: 'Guarantor was set.',
      GUARANTOR_RESET: 'Reset Guarantor',
      GUARANTOR_RESET_SUBMIT: 'Reset',
      GUARANTOR_RESET_SUCCESS: 'Guarantor was reset.',
      CREDIT_REPORT_START: 'Start Credit Score Report',
      COSIGNER_SET: 'Set Cosigners',
      COSIGNER_SET_SUBMIT: 'Set',
      COSIGNER_SET_SUCCESS: 'Cosigner group created.',
      COSIGNER_RESET: 'Reset Cosigner',
      COSIGNER_RESET_SUBMIT: 'Reset',
      COSIGNER_RESET_SUCCESS: 'Cosigner was reset.',
      CREDIT_REPORT_START_SUBMIT: 'Start',
      CREDIT_REPORT_RESTART: 'Restart Credit Score Report',
      CREDIT_REPORT_RESTART_SUBMIT: 'Restart',
      CREDIT_REPORT_DOWNLOAD: 'Downdload Credit Score Report',
      CREDIT_REPORT_CANCEL: 'Cancel Credit Score Report',
      CREDIT_REPORT_CANCEL_SUBMIT: 'Cancel',
    },
    ERR: {
      NO_ID: 'Missing user_id',
      NOT_FOUND: 'User Not Found',
      GUARANTOR_EXIST: 'User already has a guarantor.',
      NOT_COSIGNER: 'User does not belong to a cosigner group.',
    },
    LIST_EMPTY: 'No users found.',
  } as const

  export type Create = Pick<
    AdminUser,
    | 'assets_override'
    | 'bypass_credit_check'
    | 'email'
    | 'first_name'
    | 'identity_verified_at'
    | 'income_override'
    | 'last_name'
    | 'phone_number'
    | 'public_records'
    | 'score_override'
  > & {
    roles?: UserRole[]
  }

  export type Update = Partial<
    User.Update &
      Pick<
        AdminUser,
        | 'ai_summary'
        | 'assets_override'
        | 'bypass_credit_check'
        | 'credit_score'
        | 'email'
        | 'identity_verified_at'
        | 'income_override'
        | 'public_records'
        | 'reported_gross_income'
        | 'score_override'
        | 'score_warnings_config'
      > & {
        roles: UserRole[]
      }
  >

  export type SetGuarantor = {
    accepted?: boolean
    guarantor_id: string
    guarantee_id: string
  }
  export type SetCosigner = {
    accepted?: boolean
    cosigner_ids: string[]
  }
  export type ResetCosigner = {
    cosigners_id: string
    user_id: string
  }

  export const BOOLEAN_FIELDS: (keyof PickType<Update, boolean>)[] = ['pets', 'bypass_credit_check']
  export const NUMBER_FIELDS: (keyof PickType<Update, number>)[] = [
    'assets_override',
    'score_override',
    'income_override',
    'reported_gross_income',
    'public_records',
    'credit_score',
  ]
  export const DATE_FIELDS: (keyof PickType<Update, string> &
    ('dob' | 'identity_verified_at' | 'phone_verified_at' | 'terms_accepted_at'))[] = [
    'dob',
    'identity_verified_at',
    'phone_verified_at',
    'terms_accepted_at',
  ]
  export const STRING_FIELDS: Exclude<
    keyof PickType<Update, string>,
    (typeof DATE_FIELDS)[number]
  >[] = ['first_name', 'last_name', 'employer', 'phone_number', 'email', 'ai_summary']

  export const getRolesHash = (roles?: UserRole[]) => roles?.sort().join() ?? ''

  export const getDiff = createDiff<AdminUser, Update>({
    date: DATE_FIELDS,
    boolean: BOOLEAN_FIELDS,
    number: NUMBER_FIELDS,
    string: STRING_FIELDS,
    roles: getRolesHash,
  })

  export const enum Selector {
    id = 'id',
    finance = 'finance',
    brief = 'brief',
  }

  export type Sort =
    | 'user_id'
    | 'email'
    | 'last_activity_at'
    | 'first_name'
    | 'last_name'
    | 'created_at'
  export type Query = ListQuery<
    Sort,
    {
      /** Accepted unit invitation last X days */
      accepted_invitation_lastx?: number
      /** Relationship with an agent by agent id (tenant, applicant or invited to a unit) */
      agent_user_id_tenants?: string[]
      invite_unit_id?: string[]
      invited_by_id?: string[]
      /** created_at is within X days */
      created_lastx?: number
      owner_tenants?: string[]
      /** Relationship with an owner by owner id (tenant, applicant or invited to a unit) */
      owner_user_id_tenants?: string[]
      owner?: string[]
      property_manager_id?: string[]
      role?: string[]
      unit_agent?: string[]
      user_id?: string | string[]
    },
    Selector
  >
  export type Filter = Query['filter']

  export const hasRoleAgent = ({ roles }: AdminUser) => !!roles?.includes(UserRole.Agent)
  export const hasRoleOwner = ({ roles }: AdminUser) => !!roles?.includes(UserRole.Owner)
  export const hasRoleAdmin = ({ roles }: AdminUser) => !!roles?.includes(UserRole.Admin)
  export const hasRoleSupport = ({ roles }: AdminUser) => !!roles?.includes(UserRole.Support)
  export const isAgentNotOwnerNotAdmin = (user: AdminUser) =>
    hasRoleAgent(user) && !hasRoleOwner(user) && !hasRoleAdmin(user)
  export const isOwnerNotAdmin = (user: AdminUser) => hasRoleOwner(user) && !hasRoleAdmin(user)
  export const isRenter = some(hasRoleAdmin, every(not(hasRoleOwner), not(hasRoleAgent)))
  export const isGuarantor = User.isGuarantor
  export const hasGuarantor = (user: AdminUser) => !!user.guarantor_id || !!user.guarantor
  export const hasCosignerGroup = (user: AdminUser) => !!user.cosigner?.cosigners_id
  export const getChartData = (user: AdminUser) => AccountScore.toChartData(user.monthly_data)

  export const enum IdentityMatch {
    Perfect = 'Perfect',
    Strong = 'Strong',
    Partial = 'Partial',
    Mismatch = 'Mismatch',
  }
  const IdentityMatchLabel = {
    [IdentityMatch.Perfect]: 'Perfect identity match',
    [IdentityMatch.Strong]: 'Strong identity match',
    [IdentityMatch.Partial]: 'Partial identity match',
    [IdentityMatch.Mismatch]: 'Mismatch identity',
  }
  export const getIdentityScoreLabel = (score: IdentityMatch) => IdentityMatchLabel[score] ?? null

  export const getIdentityScore = (user: AdminUser) => {
    if (typeof user.identity_match_score !== 'number') return null
    switch (true) {
      case user.identity_match_score === 100:
        return IdentityMatch.Perfect
      case user.identity_match_score >= 85:
        return IdentityMatch.Strong
      case user.identity_match_score >= 70:
        return IdentityMatch.Partial
      default:
        return IdentityMatch.Mismatch
    }
  }

  /**
   * Get the average income for a user:\
   * `(estimated_gross_income_min + estimated_gross_income_max) / 2`\
   * If one of the values is missing, returns the other
   * If both are missing, returns `null`
   */
  const getAverageIncome = (user: AdminUser) => {
    const { estimated_gross_income_min, estimated_gross_income_max } = user
    const hasMin = typeof estimated_gross_income_min === 'number'
    const hasMax = typeof estimated_gross_income_max === 'number'
    if (hasMin && hasMax) return (estimated_gross_income_min + estimated_gross_income_max) / 2
    return hasMin ? estimated_gross_income_min : hasMax ? estimated_gross_income_max : null
  }

  export const MIN_AVG_INCOME = 100

  /**
   * If a user does not have a monthly income (avg < `AdminUser.MIN_AVG_INCOME`) we should show "Not Detected"
   * instead of the current low number (sometimes we show $1 income)
   */
  export const isGrossIncomeDetected = (user: AdminUser) => {
    const income = getAverageIncome(user)
    return typeof income === 'number' && income > MIN_AVG_INCOME
  }
  export const isIncomeDetected = (user: AdminUser) => {
    return typeof user.net_income === 'number' && user.net_income > MIN_AVG_INCOME
  }
  export const isRentDetected = (user: AdminUser) => {
    return typeof user.past_rent === 'number' && user.past_rent > MIN_AVG_INCOME
  }
  export const getGuarantorScore = (user: AdminUser) =>
    user.score_override ?? user.guarantor_score ?? 0

  export const hasVisibleWarnings = (user: AdminUser) =>
    !!user.score_warnings && Object.keys(user.score_warnings).length > 0
  export const hasHiddenWarnings = (user: AdminUser) =>
    !!user.score_warnings_config && Object.keys(user.score_warnings_config).length > 0
  export const hasWarnings = (user: AdminUser) =>
    hasVisibleWarnings(user) || hasHiddenWarnings(user)

  export const isWarningResolved = (user: AdminUser, type: ScoreWarnings.Type) =>
    !!user.score_warnings_config?.[type]

  export const getScoreWarnings = (user: AdminUser) => {
    const result: (UserScoreWarning & { hidden: boolean })[] = []
    // Iterate over types so the order is consistent
    ScoreWarnings.TYPES.forEach((code) => {
      const hidden = user.score_warnings?.[code]
        ? false
        : user.score_warnings_config?.[code]
        ? true
        : undefined
      if (hidden === undefined) return
      const warn = ScoreWarnings.codeToUserWarning(code)
      if (warn) result.push({ ...warn, hidden })
    })
    return result
  }

  export function parseSearchParams(
    searchParams: URLSearchParams,
    _filter?: Filter,
  ): { query: Query; sort: Sort | null; order: Order | null } {
    const filter: Filter = {}
    const global = searchParams.get('global') as string
    if (global) filter.global = global
    const pagination = parsePagination(searchParams)
    const order = parseOrder<Sort>(searchParams, {
      order: Order.desc,
      sort: 'last_activity_at',
    })
    const sort = order?.[0].name ?? null
    const query = {
      pagination,
      ...(order && { order }),
      filter: { ...filter, ..._filter },
    }
    return {
      query,
      sort,
      order: sort ? (order?.[0].desc ? Order.desc : Order.desc) : null,
    }
  }

  export function getFilterFor(user: AdminUser): AdminUser.Filter {
    if (AdminUser.hasRoleOwner(user)) return { owner_user_id_tenants: [user.user_id] }
    if (AdminUser.hasRoleAgent(user)) return { agent_user_id_tenants: [user.user_id] }
    return {}
  }

  export const toServerData = (data: Update) => {
    return convertToServerData(data, {
      date: DATE_FIELDS,
      number: NUMBER_FIELDS,
      string: STRING_FIELDS,
    })
  }
}

export class AdminUserBackend extends Client {
  create = async (
    { roles, assets_override, income_override, score_override, ...data }: AdminUser.Create,
    config?: PostConfig,
  ): Promise<AdminUser> => {
    type Req = {
      values: Omit<
        AdminUser.Create,
        'roles' | 'assets_override' | 'income_override' | 'score_override'
      >
    }
    type Res = { user: AdminUser; status: 'created' }
    const { user } = await this.post<Req, Res>('/admin/user/create', { values: data }, config)
    const userWithOverrides = await this.applyOverrides(user, {
      ...(assets_override && { assets_override }),
      ...(income_override && { income_override }),
      ...(score_override && { score_override }),
    })
    if (roles) {
      await this.updateRoles(user.user_id, roles, config).then(() => {
        userWithOverrides.roles = roles
      })
    }
    return userWithOverrides
  }

  list = async (query: AdminUser.Query = {}, config?: PostConfig) => {
    type Selector = (typeof query)['selector']

    type User = Selector extends AdminUser.Selector.finance
      ? AdminUser.WithFinance
      : Selector extends AdminUser.Selector.brief
      ? AdminUser.Brief
      : AdminUser
    type Result = { users: User[]; status: 'success' }
    const { users } = await this.post<AdminUser.Query, Result>('/admin/user/get', query, config)
    return users
  }

  getOne = async (query: AdminUser.Query = {}, config?: PostConfig) => {
    const [user] = await this.list({ ...query, pagination: { page_size: 1, page: 1 } }, config)
    return user
  }

  listAgentOptions = async (query: AdminUser.Query = {}, config?: PostConfig) => {
    const users = await this.list(
      { ...query, filter: { ...query.filter, role: [UserRole.Agent] } },
      config,
    )
    return users.map((user) => ({ label: getFullNameOrEmail(user), value: user.user_id }))
  }

  count = async (query: AdminUser.Query = {}, config?: PostConfig): Promise<number> => {
    const { count } = await this.post<AdminUser.Query, { count: number; status: 'success' }>(
      '/admin/user/count',
      query,
      config,
    )
    return count
  }

  getUserAccountScores = async (id: string, config?: GetConfig): Promise<AccountScore[]> => {
    type Result = { status: string; account_scores: AccountScore[] }
    const { account_scores } = await this.get<Result, { uid: string }>(
      `/admin/user/finance/details/get`,
      { uid: id },
      config,
    )
    return account_scores
  }

  remove = async (id?: string, config?: DeleteConfig): Promise<void> => {
    if (!id) throw new Error('Missing user id')
    throw new Error('Not implemented')
  }

  byId = async (id: string, config?: PostConfig): Promise<AdminUser> => {
    const { users } = await this.post<AdminUser.Query, { users: AdminUser[]; status: 'success' }>(
      '/admin/user/get',
      { filter: { user_id: [id] } },
      config,
    )
    const user = users[0]
    if (!user) throw new Error(AdminUser.MSG.ERR.NOT_FOUND)
    return user
  }

  briefById = async (id: string, config?: PostConfig): Promise<AdminUser> => {
    return this.getOne({ filter: { user_id: [id] }, selector: AdminUser.Selector.brief }, config)
  }

  briefByIds = async (user_id: string[], config?: PostConfig): Promise<AdminUser.Brief[]> => {
    const users = await this.list(
      { filter: { user_id }, selector: AdminUser.Selector.brief },
      config,
    )
    return user_id
      .map((user_id) => users.find(User.byId(user_id)))
      .filter(Boolean) as AdminUser.Brief[]
  }

  financeById = async (id: string, config?: PostConfig): Promise<AdminUser.WithFinance> => {
    const [user] = await this.list(
      { filter: { user_id: [id] }, selector: AdminUser.Selector.finance },
      config,
    )
    if (!user) throw new Error(AdminUser.MSG.ERR.NOT_FOUND)
    return user
  }

  update = async (
    user_id: string,
    { roles, score_override, ...updates }: AdminUser.Update,
    config?: PostConfig,
  ): Promise<AdminUser> => {
    type Request = { updates: Omit<Partial<AdminUser>, 'roles'>; user_id: string }
    const { user } = await this.post<Request, { user: AdminUser }>(
      '/admin/user/update',
      { updates: AdminUser.toServerData(updates), user_id },
      config,
    )
    return user
  }

  updateRoles = async (
    user_id: string,
    roles: UserRole[],
    config?: PostConfig,
  ): Promise<AdminUser.Id> => {
    if (!user_id) throw new Error(AdminUser.MSG.ERR.NO_ID)
    const result = await this.post<
      { roles: string[]; user_id: string },
      { user_id: string; status: string }
    >('/admin/user/set/roles', { roles, user_id }, config)
    return { user_id: result.user_id }
  }

  /**
   * Overrides Score for a user(this version is for admin only)
   * @see https://api-dev.rello.co/swagger/index.html#/admin/post_admin_user_score_override
   */
  updateScoreOverride = async (
    user_id: string,
    score: number,
    config?: PostConfig,
  ): Promise<void> => {
    if (!user_id) throw new Error(AdminUser.MSG.ERR.NO_ID)
    await this.post<{ score: number; user_id: string }, { status: string }>(
      '/admin/user/score/override',
      { score, user_id },
      config,
    )
  }
  /**
   * Overrides user assets (this version is for admin only)
   * @see https://api-dev.rello.co/swagger/index.html#/admin/post_admin_user_assets_override
   */
  updateAssetsOverride = async (
    user_id: string,
    assets: number,
    config?: PostConfig,
  ): Promise<void> => {
    if (!user_id) throw new Error(AdminUser.MSG.ERR.NO_ID)
    await this.post<{ assets: number; user_id: string }, { status: string }>(
      '/admin/user/assets/override',
      { assets, user_id },
      config,
    )
  }
  /**
   * Overrides Income for a user(this version is for admin only)
   * @see https://api-dev.rello.co/swagger/index.html#/admin/post_admin_user_income_override
   */
  updateIncomeOverride = async (
    user_id: string,
    income: number,
    config?: PostConfig,
  ): Promise<void> => {
    if (!user_id) throw new Error(AdminUser.MSG.ERR.NO_ID)
    await this.post<{ income: number; user_id: string }, { success: string }>(
      '/admin/user/income/override',
      { income, user_id },
      config,
    )
  }

  applyOverrides = async (
    user: AdminUser,
    {
      assets_override,
      income_override,
      score_override,
    }: Pick<AdminUser, 'assets_override' | 'income_override' | 'score_override'>,
    config?: PostConfig,
  ): Promise<AdminUser> => {
    let result = user
    await Promise.all([
      typeof assets_override === 'number' &&
        this.updateAssetsOverride(user.user_id, assets_override, config).then(() => {
          result = { ...result, assets_override }
        }),
      typeof income_override === 'number' &&
        this.updateIncomeOverride(user.user_id, income_override, config).then(() => {
          result = { ...result, income_override }
        }),
      typeof score_override === 'number' &&
        this.updateScoreOverride(user.user_id, score_override, config).then(() => {
          result = { ...result, score_override }
        }),
    ])
    return result
  }

  downloadCreditScoreBlobById = async (uid: string, config?: GetConfig): Promise<Blob | null> => {
    try {
      const blob = await this.get<Blob, { uid: string }>(
        '/admin/user/credit/check/pdf/download',
        { uid },
        { ...config, responseType: 'blob' },
      )
      if (blob.size === 0) throw new Error('Empty blob')
      return blob
    } catch (e) {
      return null
    }
  }

  paginatedList = createPaginatedList(this.list, this.count)

  /**
   * This will reset previous guarantors the guarantee added.
   * @see https://api-dev.rello.co/swagger/index.html#/admin/post_admin_guarantor_set
   */
  setGuarantor = async (data: AdminUser.SetGuarantor, config?: PostConfig): Promise<void> => {
    await this.post('/admin/guarantor/set', data, config)
  }

  /**
   * The provided guarantee_id is for the guarantee. It will remove any guarantor the user have.
   * This is an admin request
   * @see https://api-dev.rello.co/swagger/index.html#/admin/post_admin_guarantor_reset
   */
  resetGuarantor = async (guarantee_id: string, config?: PostConfig): Promise<void> => {
    await this.post('/admin/guarantor/reset', { guarantee_id }, config)
  }

  /**
   * Setup a cosigner group to users.
   * @see https://api-dev.rello.co/swagger/index.html#/admin/post_admin_cosigner_set
   */
  setCosigner = async (data: AdminUser.SetCosigner, config?: PostConfig): Promise<void> => {
    await this.post<AdminUser.SetCosigner, { cosigners_id: string; status: string }>(
      '/admin/cosigner/set',
      data,
      config,
    )
  }

  /**
   * Remove a user from cosigner group details.
   * @see https://api-dev.rello.co/swagger/index.html#/admin/post_admin_cosigner_reset
   */
  resetCosigner = async (data: AdminUser.ResetCosigner, config?: PostConfig): Promise<void> => {
    await this.post<AdminUser.ResetCosigner, { status: string }>(
      '/admin/cosigner/reset',
      data,
      config,
    )
  }
}

export const adminUser = new AdminUserBackend()
