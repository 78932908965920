import { Client, PostConfig, DeleteConfig } from 'client'
import { endOfDayUTC, parseDate, startOfDayUTC } from 'utils/date'
import { ListQuery, Order, buildListQuery, parseListURLParams } from 'utils/list'
import { OwnerPaymentAccountType } from './owner-payment-account-type'
import { AdminUser } from '../user/user.admin'

interface BaseLedgerItem {
  amount: number
  account_type?: OwnerPaymentAccountType
  balance: number
  created_at: string
  deposited_at?: string
  lease_id: string
  notes?: string
  reference: string
  target_account: string
  title: string
  transaction_id: string
  user_id: string
  user: Pick<AdminUser, 'email' | 'first_name' | 'last_name' | 'created_at'>
}

interface ChargeLedgerItem extends BaseLedgerItem {
  type: Ledger.Type.MISC | Ledger.Type.RENT | Ledger.Type.DEPOSIT
}

interface FailedLedgerItem extends BaseLedgerItem {
  related_transaction?: ChargeLedgerItem
  related_transaction_id?: string
  type: Ledger.Type.FAILED
}

export type LedgerItem = ChargeLedgerItem | FailedLedgerItem

export namespace Ledger {
  export type Id = Pick<LedgerItem, 'transaction_id'>
  export type Sort = 'created_at' | 'paid_at' | 'failed_at' | 'amount' | 'title' | 'deposited_at'
  export type Query = ListQuery<
    Sort,
    {
      agent_id?: string[]
      date?: {
        from?: string
        to?: string
      }
      is_failed?: boolean
      is_paid?: boolean
      lease_id?: string[]
      owner_id?: string[]
      owner_user_id?: string[]
      property_id?: string[]
      transaction_id?: string[]
      type_id?: string[]
      user_id?: string[]
      account_type?: OwnerPaymentAccountType[]
    }
  >
  export type Filter = Query['filter']

  export const enum Type {
    MISC = 'misc',
    RENT = 'rent',
    DEPOSIT = 'deposit',
    FAILED = 'failed',
    REFUND = 'refund',
  }

  export const isFailed = (item: LedgerItem): item is FailedLedgerItem => item.type === Type.FAILED
  export const isPayment = (item: LedgerItem) => item.amount < 0
  export const isCharge = (item: LedgerItem) => !isFailed(item) && item.amount > 0

  export const parseQuery = ({ searchParams }: URL, filter?: Query['filter']): Query => {
    const params = parseListURLParams<Sort>(searchParams, {
      sort: 'created_at',
      order: Order.desc,
    })
    const property_id = searchParams.get('property_id')?.split(',')
    const owner_id = searchParams.get('owner_id')?.split(',')
    const type_id = searchParams.get('type_id')?.split(',')
    const account_type = searchParams.get('account_type')?.split(',')
    const status = searchParams.get('status')
    const from = parseDate(searchParams.get('from'))
    const to = parseDate(searchParams.get('to'))
    const date =
      from || to
        ? {
            ...(from && { from: startOfDayUTC(from) }),
            ...(to && { to: endOfDayUTC(to) }),
          }
        : undefined

    return buildListQuery<Query>(params, {
      ...(date && { date }),
      ...(account_type?.length && { account_type }),
      ...(type_id?.length && { type_id }),
      ...(property_id?.length && { property_id }),
      ...(owner_id?.length && { owner_id }),
      ...(status === 'is_failed' && { is_failed: true }),
      ...(status === 'is_paid' && { is_paid: true }),
      ...filter,
    })
  }

  export function getFilterFor(user: AdminUser): Filter {
    if (AdminUser.hasRoleOwner(user)) return { owner_user_id: [user.user_id] }
    if (AdminUser.isAgentNotOwnerNotAdmin(user)) return { agent_id: [user.user_id] }
    return {}
  }

  export interface ChargeData {
    amount: number
    lease_id: string
    notes: string
    title: string
    type: Type
  }
  export interface PaymentData {
    amount: number
    lease_id: string
    notes: string
    title: string
    type: Type
    user_id: string
  }

  export interface Update {}

  export const TYPE_OPTIONS = [
    { value: Type.MISC, label: 'Misc.' },
    { value: Type.RENT, label: 'Rent' },
    { value: Type.DEPOSIT, label: 'Deposit' },
    { value: Type.REFUND, label: 'Refund' },
  ]
}

export class LedgerBackend extends Client {
  list = async (query?: Ledger.Query, config?: PostConfig) => {
    const { ledger } = await this.post<Ledger.Query, { ledger: LedgerItem[]; status: string }>(
      '/ledger/get',
      query,
      config,
    )
    return ledger
  }

  count = async ({ filter, selector }: Ledger.Query = {}, config?: PostConfig) => {
    const { count } = await this.post<Ledger.Query, { count: number; status: string }>(
      '/ledger/count',
      { filter, selector },
      config,
    )
    return count
  }

  createCharge = async (data: Ledger.ChargeData, config?: PostConfig) => {
    const { transaction_id } = await this.post<
      Ledger.ChargeData,
      { status: string; transaction_id: string }
    >('/ledger/charge/new', data, config)
    return transaction_id
  }

  createPayment = async (data: Ledger.PaymentData, config?: PostConfig) => {
    const { transaction_id } = await this.post<
      Ledger.ChargeData,
      { status: string; transaction_id: string }
    >('/ledger/payment/new', data, config)
    return transaction_id
  }

  byId = async (transaction_id: string, config?: PostConfig) => {
    const [item] = await this.list({ filter: { transaction_id: [transaction_id] } }, config)
    if (!item) throw new Error('Transaction not found')
    return item
  }

  update = async (transaction_id: string, data: Ledger.Update, config?: PostConfig) => {
    await this.post<Ledger.Update & Ledger.Id, any>(
      '/ledger/update',
      { ...data, transaction_id },
      config,
    )
  }

  remove = async ({ transaction_id }: Ledger.Id, config?: DeleteConfig) => {
    await this.delete('/ledger/delete', { ...config, params: { t: transaction_id } })
  }
}

export const ledger = new LedgerBackend()
