import { Client, PostConfig } from 'client'
import { Lease } from './lease'
import { LeaseDraft } from './lease-draft.admin'
import { Token } from '../template'

export interface LeasePreview {
  draft_id: string
  snapshot_id: string
  fields: Token[]
  vars: Record<string, any>
}

export namespace LeasePreview {
  export const MSG = {
    ERR: {
      NO_ID: 'Missing lease_id/draft_id.',
      DOWNDLOAD_FAILED: 'Failed to load PDF.',
    },
  }
}

export class LeasePreviewBackend extends Client {
  /**
   * @see https://api-dev.rello.co/swagger/index.html#/lease/post_lease_preview
   */
  preview = async (data: Lease.Id | LeaseDraft.Id, config?: PostConfig) => {
    if (!(data as LeaseDraft.Id).draft_id && !(data as Lease.Id).lease_id)
      throw new Error(LeasePreview.MSG.ERR.NO_ID)
    type Req = Lease.Id | LeaseDraft.Id
    type Res = { preview: LeasePreview; status: string }
    const { preview } = await this.post<Req, Res>('/lease/preview', data, config)
    return preview
  }

  /** @see https://api-dev.rello.co/swagger/index.html#/lease/get_lease_snapshot_download */
  downloadSnapshotById = async (sid: string, config?: PostConfig) => {
    const blob = await this.get<Blob, { sid: string }>(
      '/lease/snapshot/download',
      { sid },
      { responseType: 'blob', ...config },
    )
    if (!blob) throw new Error(LeasePreview.MSG.ERR.DOWNDLOAD_FAILED)
    return blob
  }

  /** @see https://api-dev.rello.co/swagger/index.html#/lease/post_lease_preview_exit */
  exit = async (data: Lease.Id, config?: PostConfig) => {
    await this.post<Lease.Id, { status: string }>('/lease/preview/exit', data, config)
  }
}

export const leasePreview = new LeasePreviewBackend()
