import { Owner } from './owner'

export interface QualificationScore {
  income: number
  income_asset: number
  rello: number
  /**
   * When {true} users will be able to submit applications regardless of their individual score.
   */
  voucher?: boolean
}

export namespace QualificationScore {
  export const enum Type {
    rello = 'rello',
    income = 'income',
    income_asset = 'income_asset',
  }
  // keyof QualificationScore

  const LABELS: Record<Type, string> = {
    [Type.rello]: 'Rello',
    [Type.income]: 'Income',
    [Type.income_asset]: 'Assets',
  } as const
  export const TYPES = Object.keys(LABELS) as Type[]

  export const getLabel = (type: Type) => LABELS[type]

  /** @returns {number | undefined} score based on owner's preferences */
  export const getOwnerQualificationScore = (
    score: QualificationScore,
    owner?: Owner,
    options?: { forceQualification?: boolean },
  ) => {
    if (!options?.forceQualification) {
      if (owner?.allow_nonqualified_applications) return undefined
    }
    return Math.max(score.income ?? 0, score.income_asset ?? 0, score.rello ?? 0)
  }
}
