import { SHOW_ERROR_DETAILS } from 'const'
import { FC } from 'react'
import { useRouteError, isRouteErrorResponse } from 'react-router-dom'
import { Button, Description, Dialog, HeadsUp, useNavigateOut } from 'ui'
import { useReload } from './use-reload'

interface Props {}

export const ErrorDialog: FC<Props> = () => {
  const navigateOut = useNavigateOut()
  const error = useRouteError()
  const isRouteResponse = isRouteErrorResponse(error)

  let message =
    (isRouteResponse && (error.data?.message as string)) ||
    (error as Error)?.message ||
    'Unknown error'

  const isModuleLoadError =
    message.startsWith('Failed to fetch dynamically imported module') ||
    message.startsWith('Loading chunk')
  if (isModuleLoadError) {
    message = 'Failed to load the page. Reloading...'
  }
  useReload(isModuleLoadError)

  return (
    <Dialog>
      <header>
        <h1>Oops</h1>
      </header>
      {isRouteResponse && (
        <Description>
          <h2>{error.status}</h2>
          <p>{error.statusText}</p>
        </Description>
      )}
      {SHOW_ERROR_DETAILS && <HeadsUp.Error>{message}</HeadsUp.Error>}
      <footer>
        <Button onClick={navigateOut} theme="primary">
          Back
        </Button>
      </footer>
    </Dialog>
  )
}
