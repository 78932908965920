import {
  BaseHtmlValidationProps,
  BaseHtmlValidationInput,
} from '../input-text/base-html-validation-input'

export class HiddenInput extends BaseHtmlValidationInput<
  HiddenInput.Value,
  HiddenInput.Props,
  HTMLInputElement
> {
  static defaultProps = {
    valueType: 'string',
  }

  getValue(): HiddenInput.Value | null | undefined {
    const value = super.getValue()
    return !value ? value : this.props.valueType === 'number' ? Number(value) : value
  }

  render() {
    const { validation, defaultValue, valueType, ...props } = this.props
    return (
      <input
        {...props}
        defaultValue={defaultValue ? String(defaultValue) : undefined}
        ref={this.setElement}
        id={props.id ?? props.name}
        onChange={this.handleChange}
        type="hidden"
      />
    )
  }
}

export namespace HiddenInput {
  export type Value = string | number
  export type Props = Omit<BaseHtmlValidationProps<Value>, 'type'> & {
    valueType?: `${Value}`
  }
}
